export const PRICEBOOK_TAGS = {
  BUSINESS_CONTINUITY: 'businessContinuity',
  HAS_LEASE_OPTIONS: 'hasLeaseOptions',
  NO_MAD_APPLIES_UNLESS_SALESOPS_OVERRIDE: 'noMadAppliesUnlessSalesOpsOverride',
  HIDE_DEALER_NET_TOGGLE: 'hideDealerNetToggle',
  HIDE_PARTNER_MARGIN_ELEMENTS: 'hidePartnerMarginElements',
  SUPPORTS_RENTAL_GREAT_AMERICA: 'supportsRentalGreatAmerica',
  SUPPRESS_NAVITAS_PROMOTIONAL_LEASE_RATE: 'suppressNavitasPromotionalLeaseRate',
  HAS_QUICK_SET_MARKUP_NRC: 'hasQuickSetMarkupNRC',
  HAS_QUICK_SET_MARKUP_RC: 'hasQuickSetMarkupRC',
  SUPPORTS_NEW_DAAS: 'supportsNewDaaS',
  SUPPORTS_SWITCHVOX_SIP_STATION: 'supportsSwitchvoxSIPStation',
  SUPPORTS_SEPARATE_MAD_SPIFF_OVERRIDE: 'supportsIndependentMADSPIFFOverrides',
  SUPPORTS_SMART_OFFICE: 'supportsSmartOffice',
  SUPPORTS_RVP_DISCOUNT: 'supportsRVPDiscounts',
  CPAAS_LOCATION_ENFORCEMENT_DISABLED: 'CPaaSLocationEnforcementDisabled',
  SUPPORTS_SANGOMA_CX: 'supportsSangomaCX',
  APPLY_ALL_MAD_TO_RECURRING_WITHOUT_SLIDER: 'applyAllMADtoRecurringWithoutSlider',
  MATCH_ADD_ON_TERM_LENGTH_TO_LOCATION_QUOTE: 'matchAddOnTermLengthToLocationQuote',
  SUPPORTS_SANGOMA_PHONE_INCENTIVE: 'supportsSangomaPhoneIncentive',
  SUPPORTS_SPEECH_ANALYTICS: 'supportsSpeechAnalytics',
  SUPPORTS_DISABLE_SANGOMA_PHONE_INCENTIVE: 'supportsDisableSangomaPhoneIncentive',
  SUPPORTS_SERVICE_INSIGHT_INITIAL_SETUP: 'supportsServiceInsightInitialSetup',
};

export const PRICEBOOK_TAGS_LIST = Object.values(PRICEBOOK_TAGS);
