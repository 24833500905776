import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ORDER_TYPES, TAX_EXEMPT_OPTIONS } from '../../common/enums';
import translator from '../../common/translate';
import { LabelField, SelectControl, ToggleControl } from '../Controls';
import CustomerName from './nested/CustomerName';
import DBA from './nested/DBA';
import DBAToggle from './nested/DBAToggle';
import OvernightGuaranteeToggle from './nested/OvernightGuaranteeToggle';
import bindMultiple from '../../common/helpers/bindMultiple';
import { AuthContext, PartnerContext, PriceBookContext } from '../../Context';

//TODO change to functional component
class CustomerInfo extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    customerData: PropTypes.object,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    hideDate: PropTypes.bool,
    customerNameError: PropTypes.string,
    customerNameEditable: PropTypes.bool,
    customerNameLocked: PropTypes.bool,
    onChangeDba: PropTypes.func,
    dbaError: PropTypes.string,
    disableDba: PropTypes.bool,
    partnerCanSOBO: PropTypes.bool,
    disableSOBO: PropTypes.bool,
    handleCustomerNameCommit: PropTypes.func,
    handleCustomerNameStateChange: PropTypes.func,
    activeAddendumExists: PropTypes.bool,
  };

  static defaultProps = {
    customerNameEditable: true,
  };

  constructor(props) {
    super(props);

    bindMultiple(
      this,
      this.handleDbaChange,
      this.handleUseDbaChange,
      this.handleTaxExemptChange,
      this.handleOvernightGuaranteeChange,
      this.handleExistingAlsAgreementChange,
      this.handleContactCenterConcurrencyChange
    );
  }

  updateModelProp(parameterValue, parameterName) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(parameterName, parameterValue);
    }
  }

  handleDbaChange(value) {
    this.props.onChangeDba((value || '').substring(0, 65), 'dba');
  }

  handleUseDbaChange(value) {
    this.props.onChangeDba(value, 'useDba');
  }

  handleTaxExemptChange(value) {
    this.updateModelProp(value, 'taxExempt');
  }

  handleOvernightGuaranteeChange(value) {
    this.updateModelProp(value, 'overnightGuarantee');
  }

  handleExistingAlsAgreementChange(value) {
    this.updateModelProp(value, 'existingAlsAgreement');
  }

  handleContactCenterConcurrencyChange(value) {
    this.updateModelProp(value, 'contactCenterConcurrency');
  }

  render() {
    const {
      customerData,
      lockedState,
      disabledState,
      customerNameEditable,
      customerNameLocked,
      disableDba,
      dbaError,
      customerNameError,
      handleCustomerNameCommit,
      handleCustomerNameStateChange,
      activeAddendumExists,
    } = this.props;

    const { isWholeSale, isWhiteLabelWholesale } = PartnerContext.model;
    const { isPartner, isInternal, isSalesOps, hasInternalPermissions } = AuthContext.model;
    const { isSupportsSwitchvoxSIPStation, isSupportsSangomaCx } = PriceBookContext.model;
    const alsTooltip = activeAddendumExists && isInternal ? 'msg_disabled_als_tooltip' : '';
    const alsDisabled = disabledState || (activeAddendumExists && isInternal);
    const hideOvernightToggle = (isPartner && !isWholeSale) || isSupportsSwitchvoxSIPStation;
    const displayOrderTypeFields =
      [ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.ADD_ON, ORDER_TYPES.REWRITE].includes(customerData.orderType) &&
      !isSupportsSwitchvoxSIPStation;
    const displayProCustomerToggle = hasInternalPermissions && !isSupportsSwitchvoxSIPStation && !isSupportsSangomaCx;
    const displayContactCenterConcurrencyToggle = !isSupportsSangomaCx && !isSupportsSwitchvoxSIPStation;
    const existingServiceInsightCustomerToggle = customerData.existingServiceInsightCustomerToggle;

    return (
      <div>
        {customerNameEditable ? (
          <CustomerName
            {...{ customerData, disabledState }}
            key="customerNameField"
            id="customerName"
            value={customerData.customerName}
            errorText={customerData.customerName !== null ? customerNameError : null}
            onChange={handleCustomerNameCommit}
            onStateChange={handleCustomerNameStateChange}
            lockedState={lockedState || customerNameLocked}
            tooltipText={'msg_customer_name_tooltip'}
          />
        ) : (
          <div key="customerNameFieldLabel" className="dealer-customer-name">
            <LabelField
              id="customerName"
              label="msg_customer"
              className="dealer-customer-name-label"
              value={customerData.customerName}
            />
          </div>
        )}

        <div key="customerNameValidationLink" className="customer-name-validation-link-block input-abstract-component">
          <span />
          <span>
            <a href="https://partners.sangoma.com/learn/legal-name-search/" target="_blank" rel="noopener noreferrer">
              {translator.getMessage('msg_customer_name_validate_label')}
            </a>
          </span>
        </div>

        {customerData.orderType === ORDER_TYPES.NEW_CUSTOMER && (
          <>
            <DBA
              key="DBA-field"
              id="DBA"
              value={customerData.dba}
              errorText={dbaError}
              onChange={this.handleDbaChange}
              lockedState={lockedState}
              disabledState={disabledState || disableDba}
            />

            <DBAToggle
              key="DBA-toggle-field"
              id="DBA-toggle"
              value={customerData.useDba}
              onChange={this.handleUseDbaChange}
              lockedState={lockedState}
              disabledState={disabledState || disableDba}
            />
          </>
        )}

        {![ORDER_TYPES.ADD_ON, ORDER_TYPES.REDUCTION].includes(customerData.orderType) && !isWhiteLabelWholesale && (
          <div>
            <SelectControl
              id="taxExempt"
              label="msg_tax_exempt"
              key="taxExemptToggleControl"
              lockedState={lockedState}
              disabledState={disabledState}
              value={customerData.taxExempt}
              options={TAX_EXEMPT_OPTIONS}
              onChange={this.handleTaxExemptChange}
              parentClassName="short-input"
              required
              hint={customerData.taxExempt ? 'msg_tax_exempt_message' : ''}
            />
          </div>
        )}

        {hideOvernightToggle ? null : (
          <OvernightGuaranteeToggle
            value={customerData.overnightGuarantee}
            onChange={this.handleOvernightGuaranteeChange}
            orderType={customerData.orderType}
            isPurchase={customerData.isPurchase}
            isWholeSale={isWholeSale}
            isSalesOps={isSalesOps}
            lockedState={lockedState}
            disabledState={disabledState}
          />
        )}

        {displayOrderTypeFields && (
          <>
            {customerData.isRental && (
              <LabelField
                id="greatAmericaRentalAgreementOnFile"
                label="msg_rental_agreement_on_file"
                value={
                  customerData.greatAmericaRentalAgreementOnFile
                    ? translator.getMessage('msg_yes')
                    : translator.getMessage('msg_no')
                }
                textRight
              />
            )}
            {customerData.isAllInclusive && customerData.orderType === ORDER_TYPES.NEW_LOCATIONS && (
              <LabelField
                id="creditDocumentsOnFile"
                label="msg_credit_documents_on_file"
                value={
                  customerData.creditDocumentsOnFile ? translator.getMessage('msg_yes') : translator.getMessage('msg_no')
                }
                textRight
              />
            )}
            {hasInternalPermissions && customerData.orderType !== ORDER_TYPES.REWRITE && (
              <ToggleControl
                id="existingAlsAgreement"
                key="existingAlsAgreement"
                label="msg_existing_als_agreement"
                lockedState={lockedState}
                disabledState={alsDisabled}
                tooltipText={alsTooltip}
                value={customerData.existingAlsAgreement}
                onChange={this.handleExistingAlsAgreementChange}
              />
            )}
          </>
        )}

        {displayProCustomerToggle && (
          <ToggleControl
            id="proCustomerToggleControl"
            key="proCustomerToggleControl"
            label="msg_pro_customer"
            value={customerData.customerAttributes.existingSC3ProfessionalCustomer}
            lockedState={lockedState}
            disabledState={true}
          />
        )}

        {displayContactCenterConcurrencyToggle && (
          <ToggleControl
            id="contactCenterConcurrencyToggleControl"
            key="contactCenterConcurrencyToggleControl"
            label="msg_contact_center_concurrency"
            value={customerData.contactCenterConcurrency}
            onChange={this.handleContactCenterConcurrencyChange}
            lockedState={lockedState}
            disabledState={disabledState || !customerData.contactCenterConcurrencyToggleEnabled}
          />
        )}

        {existingServiceInsightCustomerToggle.isVisible ? (
          <ToggleControl key={existingServiceInsightCustomerToggle.id} {...existingServiceInsightCustomerToggle} />
        ) : null}
      </div>
    );
  }
}

export default CustomerInfo;
