import React from 'react';
import PropTypes from 'prop-types';
import { v4 as UUID } from 'uuid';
import translator from '../../../common/translate';
import * as formatter from '../../../common/helpers/format';
import PureViewComponent from '../../PureViewComponent';
import '../css/category-totals.css';

class CategoryTotals extends PureViewComponent {
  static propTypes = {
    showMarkup: PropTypes.bool,
    markupTotal: PropTypes.number,
    total: PropTypes.number,
  };

  static defaultProps = {
    showMarkup: true,
  };

  render() {
    const { total, showMarkup, markupTotal } = this.props;

    return (
      <dl className="li-category-totals">
        {showMarkup
          ? [
              <dt key={UUID()} className="title" data-label={formatter.currency(markupTotal)}>
                {translator.getMessage('msg_markup_total')}:
              </dt>,
              <dd key={UUID()} className="value">
                <label className="label">{formatter.currency(markupTotal)}</label>
              </dd>,
            ]
          : null}
        <dt className="title" data-label={formatter.currency(total)}>
          {translator.getMessage('msg_total')}:
        </dt>
        <dd className="value">
          <label>{formatter.currency(total)}</label>
        </dd>
      </dl>
    );
  }
}

export default CategoryTotals;
