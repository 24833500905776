import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translator from '../../common/translate';
import * as formatter from '../../common/helpers/format';
import { ToggleControl } from '../Controls';
import Confirmation from './nested/Confirmation';
import SpiffAmount from './nested/SpiffAmount';
import MadSlider from './nested/MadSlider';
import './css/mad-pool-override.css';
import bindMultiple from '../../common/helpers/bindMultiple';

class Discount extends Component {
  static propTypes = {
    data: PropTypes.object,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    spiffError: PropTypes.string,
    madTitle: PropTypes.node,
    isAllInclusive: PropTypes.bool,
    isFinanced: PropTypes.bool,
    showRvpFields: PropTypes.bool,
    hideNrcRvpDiscountValues: PropTypes.bool,

    overridesVisible: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    madRecurring: PropTypes.number,
    madNonRecurring: PropTypes.number,
    madRemain: PropTypes.number,
    madRecurringMax: PropTypes.number,
    onChange: PropTypes.func,
    madTotal: PropTypes.number,
    spiffWarningPooled: PropTypes.bool,
    sliderLimitValue: PropTypes.number,
    madPoolOverride: PropTypes.bool,
    madRecurringOverride: PropTypes.number,
    madUpfrontOverride: PropTypes.number,
    spiffOverride: PropTypes.number,
    canBypassConfirmation: PropTypes.bool,
    spiffDiscountOverridesForceDisabled: PropTypes.bool,
    spiffAmountWithOverride: PropTypes.number,
    spiffAmountCalculated: PropTypes.number,
    sliderValueWithOverride: PropTypes.number,
    packageIdSelected: PropTypes.number,
    recurringDiscountAltDescription: PropTypes.string,
    nonRecurringDiscountAltDescription: PropTypes.string,
    altDescriptionOptions: PropTypes.object,
    isPartnerTargetSpiffAvailable: PropTypes.bool,
    isAltDescriptionsDisabled: PropTypes.bool,
    isCalcRequested: PropTypes.bool,
    showPartnerSpiff: PropTypes.bool,
    showSpiffOverride: PropTypes.bool,
    spiffOverrideEnabled: PropTypes.bool,
    isSupportsSeparateSpiffOverride: PropTypes.bool,
    additionalRecurringDiscount: PropTypes.number,
    additionalUpfrontDiscount: PropTypes.number,
    calculatedMadRecurAmount: PropTypes.number,
    calculatedMadUpfrontAmount: PropTypes.number,
    additionalRcDiscountPercent: PropTypes.number,
    additionalUpfrontDiscountPercent: PropTypes.number,
    showSlider: PropTypes.bool,
    showSangomaPhoneIncentive: PropTypes.bool,
    sangomaPhoneIncentiveValue: PropTypes.number,
  };

  static defaultProps = {
    data: {},
    lockedState: false,
    disabledState: false,
    overridesVisible: false,
  };

  constructor(props) {
    super(props);

    bindMultiple(
      this,
      this.handleChangeMadPoolOverride,
      this.handleChangeSpiffOverrideEnabled,
      this.handleChangeSpiffAmount,
      this.handleChangeSangomaPhoneIncentive
    );
  }

  handleChange(key, value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value, key);
    }
  }

  handleChangeMadPoolOverride(value) {
    this.handleChange('madPoolOverride', value);
  }

  handleChangeSpiffOverrideEnabled(value) {
    this.handleChange('spiffOverrideEnabled', value);
  }

  handleChangeSpiffAmount(value) {
    this.handleChange('spiffAmount', value);
  }

  handleChangeSangomaPhoneIncentive(value) {
    this.handleChange('disableSangomaPhoneIncentive', value);
  }

  render() {
    const {
      lockedState,
      disabledState,
      spiffError,
      madTitle,
      isAllInclusive,
      isConfirmed,
      madRecurring,
      madNonRecurring,
      madRemain,
      madRecurringMax,
      onChange,
      madTotal,
      spiffWarningPooled,
      sliderLimitValue,
      overridesVisible,
      madPoolOverride,
      madRecurringOverride,
      madUpfrontOverride,
      spiffOverride,
      canBypassConfirmation,
      spiffDiscountOverridesForceDisabled,
      spiffAmountWithOverride,
      spiffAmountCalculated,
      sliderValueWithOverride,
      recurringDiscountAltDescription,
      nonRecurringDiscountAltDescription,
      altDescriptionOptions,
      isPartnerTargetSpiffAvailable,
      isAltDescriptionsDisabled,
      isCalcRequested,
      showPartnerSpiff,
      isFinanced,
      isSupportsSeparateSpiffOverride,
      showSpiffOverride,
      spiffOverrideEnabled,
      showRvpFields,
      additionalRecurringDiscount,
      additionalUpfrontDiscount,
      calculatedMadRecurAmount,
      calculatedMadUpfrontAmount,
      additionalRcDiscountPercent,
      additionalUpfrontDiscountPercent,
      hideNrcRvpDiscountValues,
      showSlider,
      showSangomaPhoneIncentive,
      sangomaPhoneIncentiveValue,
      disableSangomaPhoneIncentive,
      showDisableSangomaIncentiveToggle,
    } = this.props;

    const madTotalFormatted = formatter.currency(madTotal);

    return (
      <div>
        <Confirmation
          lockedState={lockedState || (spiffWarningPooled && !isConfirmed)}
          disabledState={disabledState}
          isConfirmed={isConfirmed}
          madRecurring={madRecurring}
          madNonRecurring={madNonRecurring}
          onChange={onChange}
          madPoolOverride={madPoolOverride}
          overridesVisible={overridesVisible}
          madRecurringOverride={madRecurringOverride}
          madUpfrontOverride={madUpfrontOverride}
          spiffOverride={spiffOverride}
          spiffDiscountOverridesForceDisabled={spiffDiscountOverridesForceDisabled}
          spiffAmountCalculated={spiffAmountCalculated}
          recurringDiscountAltDescription={recurringDiscountAltDescription}
          nonRecurringDiscountAltDescription={nonRecurringDiscountAltDescription}
          altDescriptionOptions={altDescriptionOptions}
          isAltDescriptionsDisabled={isAltDescriptionsDisabled}
          isCalcRequested={isCalcRequested}
          showPartnerSpiff={showPartnerSpiff}
          isAllInclusive={isAllInclusive}
          showSpiffOverride={showSpiffOverride}
          spiffOverrideEnabled={spiffOverrideEnabled}
          isSupportsSeparateSpiffOverride={isSupportsSeparateSpiffOverride}
          showRvpFields={showRvpFields}
          additionalRecurringDiscount={additionalRecurringDiscount}
          additionalUpfrontDiscount={additionalUpfrontDiscount}
          calculatedMadRecurAmount={calculatedMadRecurAmount}
          calculatedMadUpfrontAmount={calculatedMadUpfrontAmount}
          additionalRcDiscountPercent={additionalRcDiscountPercent}
          additionalUpfrontDiscountPercent={additionalUpfrontDiscountPercent}
          hideNrcRvpDiscountValues={hideNrcRvpDiscountValues}
          showSangomaPhoneIncentive={showSangomaPhoneIncentive}
          sangomaPhoneIncentiveValue={sangomaPhoneIncentiveValue}
        />

        {overridesVisible && (
          <ToggleControl
            lockedState={lockedState}
            disabledState={spiffDiscountOverridesForceDisabled || disabledState}
            parentClassName={'mad-pool-override'}
            id={'madPoolOverrideToggle'}
            label={'msg_use_mad_override'}
            value={madPoolOverride}
            onChange={this.handleChangeMadPoolOverride}
          />
        )}

        {overridesVisible && isSupportsSeparateSpiffOverride && showPartnerSpiff && (
          <ToggleControl
            lockedState={lockedState}
            disabledState={spiffDiscountOverridesForceDisabled || disabledState}
            parentClassName="spiff-override"
            id="spiffOverrideEnabled"
            label="msg_use_spiff_override"
            value={spiffOverrideEnabled}
            onChange={this.handleChangeSpiffOverrideEnabled}
          />
        )}

        {showDisableSangomaIncentiveToggle && (
          <ToggleControl
            lockedState={lockedState}
            disabledState={disabledState}
            parentClassName="spiff-override"
            id="disableSangomaPhoneIncentive"
            label="msg_disable_sangoma_phone_incentive"
            value={disableSangomaPhoneIncentive}
            onChange={this.handleChangeSangomaPhoneIncentive}
          />
        )}

        <div className="input-abstract-component mad-max-upfront">
          <div className="input-abstract-label-wrapper">
            <div className="input-abstract-label">
              <span>
                {translator.getMessage('msg_eligible_combined_discretionary_founds_for_customer_discount_spiff')}
              </span>
            </div>
          </div>

          <div className="label-field-component text-right input-component-wrapper">
            <div id="madMaxUpfront">{madTotalFormatted}</div>
          </div>
        </div>

        {isPartnerTargetSpiffAvailable && (!overridesVisible || !madPoolOverride) && (
          <SpiffAmount
            lockedState={lockedState}
            disabledState={madPoolOverride || disabledState}
            spiffWarningPooled={spiffWarningPooled}
            errorText={spiffError}
            isConfirmed={isConfirmed}
            madTotal={madTotal}
            spiffAmount={spiffAmountWithOverride}
            onChange={this.handleChangeSpiffAmount}
            canBypassConfirmation={canBypassConfirmation}
          />
        )}

        {showSlider && (
          <MadSlider
            lockedState={lockedState}
            disabledState={madPoolOverride || disabledState}
            isConfirmed={isConfirmed}
            value={sliderValueWithOverride}
            limitValue={sliderLimitValue}
            madTitle={madTitle}
            madTotal={madTotal}
            madRecurring={madRecurring}
            madNonRecurring={madNonRecurring}
            madRemain={madRemain}
            madRecurringMax={madRecurringMax}
            onChange={onChange}
            isFinanced={isFinanced}
            canBypassConfirmation={canBypassConfirmation}
          />
        )}
      </div>
    );
  }
}

export default Discount;
