import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import translator from '../../../common/translate';
import PureViewComponent from '../../PureViewComponent';
import '../css/category.css';
import LineItem from './LineItem';
import CategoryTotals from './CategoryTotals';
import { PartnerContext, PriceBookContext } from '../../../Context';
import { PACKAGE_TYPE_IDS, CATEGORY_IDS, PRICEBOOK_TAGS } from '../../../common/enums';
import ExternalLink from '../../ExternalLink';

class Category extends PureViewComponent {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    visibleLineItems: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object),
    markupTotal: PropTypes.number,
    total: PropTypes.number,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    showMarkupTotal: PropTypes.bool,
    hideAddButton: PropTypes.bool,
    hideAddButtonAndTotals: PropTypes.bool,
    isWholeSale: PropTypes.bool,
    packageIdSelected: PropTypes.number,
    addButton: PropTypes.object,
    addExistingButton: PropTypes.object,
    hasError: PropTypes.bool,
  };

  static defaultProps = {
    visibleLineItems: [],
    columns: [],
    markupTotal: 0,
    total: 0,
    lockedState: false,
    disabledState: false,
    showMarkupTotal: true,
    hideAddButton: false,
    hideAddButtonAndTotals: false,
    isWholeSale: false,
    hasError: false,
  };

  constructor(props) {
    super(props);

    this.handleUcaasAndTelecomHintClick = this.handleUcaasAndTelecomHintClick.bind(this);
  }

  renderHeaders(props) {
    const headers = [];

    for (let i = 0; i < props.columns.length; i++) {
      const column = props.columns[i];
      const headersClassName = ['cell', column.alias];
      const key = 'li-category-header-' + column.alias + '_' + props.uuid;

      if (i === 0) {
        const categoryNameClassNames = ['category-name'];

        if (props.hasError) {
          categoryNameClassNames.push('has-error');
        }

        headers.push(
          <div key={key} className={headersClassName.join(' ')}>
            <div className={categoryNameClassNames.join(' ')} title={props.name}>
              <span>{props.name}</span>
            </div>
          </div>
        );
      } else {
        headers.push(
          <div key={key} className={headersClassName.join(' ')}>
            {column.header !== null && translator.getMessage(column.header)}
          </div>
        );
      }
    }

    return headers;
  }

  ucaasAndTelecomHint() {
    const msg = this.props.intl.formatMessage({ id: 'msg_rc_ucaas_and_telecom_hint' });
    const matches = msg.match(/^(.*?)\[(.*?)\](.*)$/);

    if (!Array.isArray(matches) || !matches[1] || !matches[2] || !matches[3]) {
      return msg;
    }

    return (
      <p>
        {matches[1]}
        <a href="#quote-information-section" onClick={this.handleUcaasAndTelecomHintClick}>
          {matches[2]}
        </a>
        {matches[3]}
      </p>
    );
  }

  handleUcaasAndTelecomHintClick(ev) {
    ev.preventDefault();

    window.scrollTo(0, 0);
  }

  render() {
    const {
      total,
      lockedState,
      disabledState,
      columns,
      markupTotal,
      showMarkupTotal,
      hideAddButton,
      hideAddButtonAndTotals,
      packageIdSelected,
      /** @type {AddButtonModel} */
      addButton,
      /** @type {AddExistingButtonModel} */
      addExistingButton,
    } = this.props;

    const addButtonClassName = classNames({
      'wrapper-add-line-item-btn': true,
      active: !lockedState && !disabledState,
    });

    const cells = columns.map(d => d.alias);

    let lineItems = [...this.props.visibleLineItems]
      // KM-6620 Sort all items based on sequence value from DB
      .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      .map(d => (
        <LineItem
          {...d}
          lockedState={lockedState}
          disabledState={disabledState}
          key={'li_' + d.uuid}
          cells={cells}
          columns={columns}
        />
      ));

    return (
      <div className="li-category">
        <div className="headers">{this.renderHeaders(this.props)}</div>
        <div className="line-items">
          {lineItems.length === 0 ? (
            <div className="no-line-items-hint">{translator.getMessage('msg_no_line_items_have_been_added')}</div>
          ) : (
            lineItems
          )}
        </div>
        {!hideAddButtonAndTotals && (
          <CategoryTotals showMarkup={showMarkupTotal} markupTotal={markupTotal} total={total} />
        )}
        {
          <div className={addButtonClassName}>
            {addButton.visible && !hideAddButton ? (
              <button
                type="button"
                id={addButton.id}
                onClick={addButton.handleClick}
                disabled={addButton.disabled}
                className="large outlined-primary"
              >
                <span className="icon-add" />
                <span>{translator.getMessage('msg_add')}</span>
              </button>
            ) : null}

            {addExistingButton.visible && !hideAddButton ? (
              <button
                type="button"
                id={addExistingButton.id}
                onClick={addExistingButton.handleClick}
                disabled={addExistingButton.disabled}
                className="large outlined-primary"
              >
                <span className="icon-add" />
                <span>{translator.getMessage('msg_add_existing_line_item_button_label')}</span>
              </button>
            ) : null}

            {this.props.id === CATEGORY_IDS.phones.id && !PriceBookContext.model.isSupportsSwitchvoxSIPStation && (
              <span className="phone-link-wrapper">
                <ExternalLink
                  path="https://sangoma.com/products-and-solutions/phones-and-hardware/products/phones/"
                  title={translator.getMessage('msg_phone_literature')}
                />
                <span className="phone-link-hint">{translator.getMessage('msg_phone_link_hint')}</span>
              </span>
            )}
            {this.props.id === CATEGORY_IDS.adoptedPhones.id && (
              <span className="phone-link-wrapper">
                <ExternalLink
                  path="https://partners.sangoma.com/learn/adopted-phone-checklist/"
                  title={translator.getMessage('msg_adopted_phone_checklist_link')}
                />
              </span>
            )}
            {this.props.id === CATEGORY_IDS.numbers.id && (
              <span className="category-hint">
                {translator.getMessage('msg_please_ensure_that_each_location_has_the_appropriate')}
              </span>
            )}
            {[CATEGORY_IDS.partnerProvidedNonRecurringItems.id, CATEGORY_IDS.partnerProvidedRecurringItems.id].includes(
              this.props.id
            ) && <span className="category-hint">{translator.getMessage('msg_partner_category_hint')}</span>}
            {/* KM-5160: Starbox Sizing Guide PDF link  */}
            {this.props.id === CATEGORY_IDS.starBoxPortExtensionActivation.id && (
              <span className="starbox-sizing-link-wrapper">
                <span className="starbox-sizing-link-hint">
                  {translator.getMessage('msg_please_ensure_that_each_location_has_one')}
                </span>
                <br />
                <ExternalLink
                  path="https://s2s-support-files.s3.amazonaws.com/kb/marketing/documents/starbox-sizing-guide.pdf"
                  title={translator.getMessage('msg_starbox_sizing_guide')}
                />
              </span>
            )}
            {/* KM-7071: ATA Supported Devices link  */}
            {this.props.id === CATEGORY_IDS.ataPaging.id && (
              <span className="phone-link-wrapper">
                <ExternalLink
                  path="https://s2s-support-files.s3.amazonaws.com/kb/marketing/documents/ata-matrix.pdf"
                  title={translator.getMessage('msg_ata_supported_devices')}
                />
              </span>
            )}
            {/* KM-8022: StarDaaS Purchasing Guide link  */}
            {this.props.id === CATEGORY_IDS.starDaasRc.id && (
              <span className="phone-link-wrapper">
                <ExternalLink
                  path="https://s2s-support-files.s3.amazonaws.com/kb/marketing/documents/connected-workspace-daas-purchasing-guide.pdf"
                  title={translator.getMessage('msg_stardaas_purchasing_guide')}
                />
              </span>
            )}
            {this.props.id === CATEGORY_IDS.starDaasRc.id && (
              <span className="category-hint">
                {translator.getMessage(
                  PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_NEW_DAAS)
                    ? 'msg_daas_category_quoting_note_alternative'
                    : 'msg_daas_category_quoting_note'
                )}
              </span>
            )}
            {this.props.id === CATEGORY_IDS.ucaasAndTelecom.id && (
              <span className="category-hint">
                {/* KM-9636: Hint with a link that auto scrolls to Quote Information section */}
                {PartnerContext.model.isStarCloudPlus &&
                  [PACKAGE_TYPE_IDS.PACKAGE_TWO, PACKAGE_TYPE_IDS.PACKAGE_FOUR].includes(packageIdSelected) &&
                  this.ucaasAndTelecomHint()}
                {/* KM-10522: UCaaS and Telecom category hint */}
                <p>{translator.getMessage('msg_ucaas_and_telecom_hint')}</p>
              </span>
            )}
            {/* KM-11620 Switchvox Sizing Guide Link to Appliances Category */}
            {this.props.id === CATEGORY_IDS.appliances.id && (
              <span className="phone-link-wrapper">
                <ExternalLink
                  path="https://partners.sangoma.com/learn/switchvox-sizing-guide/"
                  title={translator.getMessage('msg_switchvox_sizing_guide')}
                />
              </span>
            )}
            {/* KM-11641 Software and Subscriptions message */}
            {this.props.id === CATEGORY_IDS.softwareAndSubscriptions.id && (
              <span className="category-hint">
                {translator.getMessage('msg_software_and_subscriptions_category_message')}
              </span>
            )}
            {/* KM-11934 SIPStation Category message */}
            {this.props.id === CATEGORY_IDS.sipStation.id && (
              <span className="category-hint">
                {translator.getMessage('msg_sip_station_category_hint')}
                <br />
                {translator.getMessage('msg_sip_station_category_hint_additional_message')}
              </span>
            )}
            {/* KM-12571  VOIP Gateways and Fax Devices Category to recommend Remote Installation Assistance message */}
            {this.props.id === CATEGORY_IDS.voipGatewaysAndFaxDevices.id && (
              <span className="category-hint">
                {translator.getMessage('msg_voip_gateways_and_fax_devices_recommend_remote_installation')}
              </span>
            )}
            {/* KM-13397 CX (Contact Center) Category message */}
            {this.props.id === CATEGORY_IDS.sangomaCxContactCenter.id && (
              <span className="category-hint">{translator.getMessage('msg_sangoma_cx_category_message')}</span>
            )}
            {/* KM-13530 Sangoma Apps Brochure link */}
            {/* KM-13638 Sangoma Apps category message */}
            {this.props.id === CATEGORY_IDS.PackagedApplications.id && (
              <span className="category-hint">
                <ExternalLink
                  path="https://partners.sangoma.com/engage/sangoma-apps-brochure-cpaas/"
                  title={translator.getMessage('msg_sangoma_apps_brochure')}
                />
                <br />
                {translator.getMessage('msg_sangoma_apps_packaged_applications_rc_category_message')}
              </span>
            )}
          </div>
        }
      </div>
    );
  }
}

export default injectIntl(Category);
