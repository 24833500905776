import React from 'react';
import { ContextConnect, ContextModel } from './';
import { ORDER_TYPES, USER_PERMISSIONS, USER_ROLES } from '../common/enums';

const context = React.createContext();

class AuthContextModel extends ContextModel {
  static TOKEN_KEY = 'token';

  constructor() {
    super();

    this._showLoginModal = false;

    this.isAgent = false;
    this.email = '';
    this.firstName = '';
    this.internalUser = false;
    this.lastName = '';
    this.permissions = [];
    this.userId = 0;
    this.signOutInProgress = false;
    /** @type {CustomerOrderModel} */
    this.customerOrder = null;

    this._ignoreOnExport.push('customerOrder');
  }

  get showLoginModal() {
    if (this.signOutInProgress) {
      return false;
    }

    return this._showLoginModal;
  }

  set showLoginModal(value) {
    this._showLoginModal = value;
  }

  get isPartner() {
    return this.role === USER_ROLES.REGULAR_USER || !this.role;
  }

  get isInternal() {
    return this.role === USER_ROLES.INTERNAL_USER;
  }

  get isSalesOps() {
    return this.role === USER_ROLES.SALES_OPERATIONS_USER;
  }

  get isRewrite() {
    return this.role === USER_ROLES.REWRITE_USER;
  }

  get hasPartnerPermissions() {
    return this.isSalesOps || this.isInternal || this.isPartner || this.isRewrite;
  }

  get hasInternalPermissions() {
    return this.isSalesOps || this.isInternal || this.isRewrite;
  }

  get hasSalesOpsPermissions() {
    return (
      this.isSalesOps || (this.isRewrite && this.customerOrder && this.customerOrder.orderType === ORDER_TYPES.REWRITE)
    );
  }

  get hasRvpPermissions() {
    return this.permissions.includes(USER_PERMISSIONS.REGIONAL_VICE_PRESIDENT);
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(d => d).join(' ');
  }

  get fullNameAndEmail() {
    return [this.fullName, this.email].filter(d => d).join(' ');
  }

  get token() {
    return localStorage.getItem(AuthContextModel.TOKEN_KEY);
  }

  set token(value) {
    localStorage.setItem(AuthContextModel.TOKEN_KEY, value);
  }
}

const model = new AuthContextModel();
const Provider = ContextConnect(context, model);
const Consumer = context.Consumer;

const AuthContext = {
  Provider,
  Consumer,
  model,
};

export default AuthContext;
