import CollapsableCard from '../../../../CollapsableCard';
import React from 'react';
import { AuthContext, PartnerContext, PriceBookContext } from '../../../../../Context';
import {
  CONTRACT_TERM_MONTH_OPTIONS,
  LINE_TYPES_OPTIONS,
  ORDER_TYPES,
  PACKAGE_TYPE_IDS,
  PRICEBOOK_TAGS,
  QUOTE_STATUS,
  SWITCHVOX_ON_PREM_TERM_YEARS_OPTIONS,
  SWITCHVOX_ON_PREM_TYPES_OPTIONS,
} from '../../../../../common/enums';
import { LabelField, NumberControl, SelectControl, TextControl, ToggleControl } from '../../../../Controls';
import NumLines from '../../../../NumLines';
import { Validate } from '../../../../../Models';
import config from '../../../../../config';
import BulkMarkup from '../../../../BulkMarkup/BulkMarkup';

const QuoteInformationWrapper = ({
  //CollapsableCard props
  collapsedSections,
  toggleCollapse,
  id,
  title,
  //Quot info inputs props
  customerOrder,
  controller,
  lockedState,
  disabledState,
  onQuoteInfoChange,
}) => {
  const { isWholeSale } = PartnerContext.model;

  //TODO create separate model for quote info inputs
  /**
   * Returns component for requested parameters
   * @param {object} item - Object parameters, imported from style.js.
   * @param {boolean} lockedState - True of object should be read only.
   * @param {boolean} disabledState - True of object should be read only.
   * @return {object} React component according to requested params.
   */
  const getComponent = (item, lockedState, disabledState) => {
    switch (item.type) {
      case 'useNumLinesDefault':
        return PriceBookContext.model.flags.hideNumLinesControlsWithDefaultValue !== undefined ||
          [PACKAGE_TYPE_IDS.PACKAGE_ONE, PACKAGE_TYPE_IDS.PACKAGE_THREE].includes(customerOrder.packageIdSelected) ||
          PriceBookContext.model.isSupportsSwitchvoxSIPStation ? null : (
          <ToggleControl
            {...item.props}
            key={'use-num-lines-default-' + customerOrder.uuid}
            lockedState={lockedState}
            disabledState={disabledState}
            value={customerOrder.useNumLinesDefault}
            onChange={_value => onQuoteInfoChange('useNumLinesDefault', _value)}
          />
        );
      case 'NumLines':
        return PriceBookContext.model.flags.hideNumLinesControlsWithDefaultValue !== undefined ||
          [PACKAGE_TYPE_IDS.PACKAGE_ONE, PACKAGE_TYPE_IDS.PACKAGE_THREE].includes(customerOrder.packageIdSelected) ||
          PriceBookContext.model.isSupportsSwitchvoxSIPStation ? null : (
          <NumLines
            lockedState={lockedState}
            disabledState={disabledState}
            key={'num-lines-' + customerOrder.uuid}
            id={'master-order-' + customerOrder.uuid}
            tooltipText={'Default value is ' + customerOrder.numLinesDefault}
            editable={!customerOrder.useNumLinesDefault}
            value={customerOrder.numLines}
            onChange={_value => onQuoteInfoChange('numLines', _value)}
            label={'msg_number_of_lines'}
          />
        );
      case 'contractTermSelect':
        if (
          AuthContext.model.hasInternalPermissions ||
          ![ORDER_TYPES.ADD_ON, ORDER_TYPES.REDUCTION].includes(customerOrder.orderType)
        ) {
          const sipStationEnabled =
            PriceBookContext.model.isSupportsSwitchvoxSIPStation && customerOrder.sipStationEnabled;
          const isOneMonthSelected = customerOrder[item.props.id] === CONTRACT_TERM_MONTH_OPTIONS.ONE_MONTH.value;

          const sipStationEnabledHint = isOneMonthSelected ? 'msg_not_applicable' : 'msg_sip_station_term_length';
          const switchvoxSIPStationHint = customerOrder.sipStationEnabled ? sipStationEnabledHint : 'msg_not_applicable';
          const hint = PriceBookContext.model.isSupportsSwitchvoxSIPStation ? switchvoxSIPStationHint : '';
          const autoColumns = (sipStationEnabled && AuthContext.model.isSalesOps) || isOneMonthSelected;
          const contractTermIsDisabled =
            (customerOrder.quoteStatus === QUOTE_STATUS.AMENDING && !AuthContext.model.hasSalesOpsPermissions) ||
            (PriceBookContext.model.isSupportsSwitchvoxSIPStation &&
              (!customerOrder.sipStationEnabled || customerOrder.hasFinalizedLocations)) ||
            (customerOrder.orderType === ORDER_TYPES.ADD_ON &&
              PriceBookContext.model.flags.pricebookTag.includes(
                PRICEBOOK_TAGS.MATCH_ADD_ON_TERM_LENGTH_TO_LOCATION_QUOTE
              ) &&
              !AuthContext.model.hasSalesOpsPermissions) ||
            disabledState;

          return (
            <SelectControl
              {...item.props}
              parentClassName={autoColumns ? '' : 'short-input'}
              className={autoColumns ? '' : 'right-align'}
              key={item.props.id + 'SelectControl'}
              lockedState={lockedState}
              disabledState={contractTermIsDisabled}
              value={customerOrder[item.props.id]}
              options={customerOrder.contractTermLengthOptions}
              onChange={_value => onQuoteInfoChange(item.props.id, _value)}
              hint={hint}
              autoColumns={autoColumns}
              fullWidth
            />
          );
        } else {
          return null;
        }
      case 'LocationsNumber':
        if (![ORDER_TYPES.ADD_ON].includes(customerOrder.orderType)) {
          if (ORDER_TYPES.REWRITE === customerOrder.orderType) {
            return (
              <LabelField
                id={item.id + 'LabelField'}
                key={item.id + 'LabelField'}
                parentClassName="short-input"
                label={item.props.label}
                value={customerOrder[item.id]}
                textRight
              />
            );
          }

          return (
            <NumberControl
              {...item.props}
              parentClassName="short-input"
              key={item.id + 'LocationsNumber'}
              id={item.id + 'LocationsNumber'}
              lockedState={lockedState}
              disabledState={disabledState || customerOrder.sangomaCXStandalone}
              value={customerOrder[item.id]}
              onChange={_value => onQuoteInfoChange(item.id, _value)}
              errorText={Validate.numLocations(customerOrder).text}
              commitOnBlur={true}
            />
          );
        } else {
          return null;
        }
      case 'StarCloudLocations': {
        if (customerOrder.sangomaCXStandalone) {
          return null;
        }

        //TODO move this if statement to the guard clause above
        if (
          (config.userInputsSolutionTypeEnabled || AuthContext.model.hasSalesOpsPermissions) &&
          !PriceBookContext.model.isSupportsSwitchvoxSIPStation &&
          !customerOrder.standaloneServiceNonUCaaS
        ) {
          if (ORDER_TYPES.REWRITE === customerOrder.orderType) {
            return (
              <LabelField
                id={item.id + 'LabelField'}
                key={item.id + 'LabelField'}
                parentClassName="short-input"
                label={item.props.label}
                value={customerOrder[item.id]}
                textRight
              />
            );
          }

          return (
            <NumberControl
              {...item.props}
              parentClassName="star-cloud-locations short-input"
              key={item.id + 'LocationsNumber'}
              id={item.id + 'LocationsNumber'}
              lockedState={lockedState}
              disabledState={disabledState}
              value={customerOrder[item.id]}
              onChange={_value => onQuoteInfoChange(item.id, _value)}
            />
          );
        } else {
          return null;
        }
      }
      case 'BusinessVoicePlusLocations':
        if (
          PriceBookContext.model.isSupportsSwitchvoxSIPStation ||
          customerOrder.standaloneServiceNonUCaaS ||
          customerOrder.sangomaCXStandalone
        ) {
          return null;
        }

        const value = customerOrder.numLocations - customerOrder.numStarCloudLocations;

        return (
          <LabelField
            id={item.id + 'LabelField'}
            key={item.id + 'LabelField'}
            parentClassName="short-input"
            label={item.props.label}
            value={value}
            textRight
          />
        );
      case 'switchvoxSIPStationLocations':
        return !PriceBookContext.model.isSupportsSwitchvoxSIPStation ? null : (
          <LabelField
            id={item.id + 'LabelField'}
            key={item.id + 'LabelField'}
            parentClassName="short-input"
            label={item.props.label}
            value={customerOrder.numSwitchvoxOnPremSIPStationLocations}
            textRight
          />
        );
      case 'lineType':
        if (
          [2, 4].includes(customerOrder.packageIdSelected) &&
          !isWholeSale &&
          PriceBookContext.model.flags.hideNumLinesControlsWithDefaultValue === undefined
        ) {
          return (
            <SelectControl
              {...item.props}
              key={item.props.id + 'SelectControl'}
              lockedState={lockedState}
              disabledState={
                disabledState ||
                (customerOrder.quoteStatus === QUOTE_STATUS.UNFINALIZED && !AuthContext.model.hasSalesOpsPermissions)
              }
              value={Number(
                Object.keys(LINE_TYPES_OPTIONS).find(d => LINE_TYPES_OPTIONS[d] === customerOrder[item.props.id])
              )}
              options={item.props.options}
              onChange={_value => onQuoteInfoChange(item.props.id, LINE_TYPES_OPTIONS[_value])}
            />
          );
        } else {
          return null;
        }
      case 'dealerNetToggle':
        if (
          config.eSignatureEnabled &&
          customerOrder.useESigDocumentsFlow &&
          !PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.HIDE_DEALER_NET_TOGGLE)
        ) {
          return (
            <ToggleControl
              {...item.props}
              key={item.props.id + 'ToggleControl'}
              lockedState={lockedState}
              disabledState={customerOrder.isFinanced || disabledState}
              value={customerOrder[item.props.id]}
              onChange={_value => onQuoteInfoChange(item.props.id, _value)}
            />
          );
        } else {
          return null;
        }
      case 'bulkMarkup':
        if (!PriceBookContext.model.flags.hidePriceAndMarkup) {
          return (
            <BulkMarkup
              onChange={onQuoteInfoChange}
              onApply={controller.setBulkMarkup}
              {...item.props}
              key={item.id}
              bulkRecurring={customerOrder.bulkRecurring}
              bulkNonRecurring={customerOrder.bulkNonRecurring}
              disabledState={disabledState}
              lockedState={lockedState}
              showNrcInputs={PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.HAS_QUICK_SET_MARKUP_NRC)}
              showRcInputs={PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.HAS_QUICK_SET_MARKUP_RC)}
            />
          );
        } else {
          return null;
        }
      case 'businessContinuity':
        return customerOrder.businessContinuity && !PriceBookContext.model.isSupportsSwitchvoxSIPStation ? (
          <ToggleControl
            lockedState
            disabledState
            id={item.props.id}
            key={item.props.id}
            label={item.props.label}
            value={customerOrder.businessContinuity}
          />
        ) : null;
      case 'coBrandingEnabled':
        if (PartnerContext.model.isWhiteLabelWholesale) {
          return null;
        }

        return (
          <ToggleControl
            disabledState={disabledState || customerOrder.partnerLogoPath === undefined}
            id={item.props.id}
            key={item.props.id}
            label={item.props.label}
            lockedState={lockedState}
            value={customerOrder.cobrandingEnabled}
            onChange={_value => onQuoteInfoChange(item.props.id, _value)}
          />
        );
      case 'quoteTitle':
        return (
          <TextControl
            id="customerTitleField"
            key="customerTitleField"
            label={item.props.label}
            lockedState={lockedState}
            disabledState={disabledState}
            required={true}
            value={customerOrder[item.props.id]}
            errorText={Validate.title(customerOrder).text}
            onChange={_value => onQuoteInfoChange(item.props.id, (_value || '').substr(0, 50))}
          />
        );
      case 'switchvoxOnPremEnabled':
      case 'sipStationEnabled':
        if (!PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
          return null;
        }
        const showYesLabel =
          (customerOrder.switchvoxExistsInOriginalLocation && item.type === 'switchvoxOnPremEnabled') ||
          (customerOrder.sipStationExistsInOriginalLocation && item.type === 'sipStationEnabled');

        return showYesLabel ? (
          <LabelField
            id={item.id + 'LabelField'}
            key={item.id + 'LabelField'}
            parentClassName="short-input"
            label={item.props.label}
            value="msg_yes"
            textRight
          />
        ) : (
          <ToggleControl
            id={item.id}
            key={item.id}
            label={item.props.label}
            disabledState={disabledState || customerOrder.hasFinalizedLocations}
            lockedState={lockedState}
            value={customerOrder[item.id]}
            onChange={_value => onQuoteInfoChange(item.id, _value)}
          />
        );
      case 'switchvoxOnPremMaintType':
        if (!PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
          return null;
        }

        if (customerOrder.switchvoxExistsInOriginalLocation) {
          const value = SWITCHVOX_ON_PREM_TYPES_OPTIONS.find(
            opt => customerOrder.switchvoxOnPremMaintTypeEffective === opt.value
          )?.text;

          return (
            <LabelField
              id={item.id + 'LabelField'}
              key={item.id + 'LabelField'}
              parentClassName="short-input"
              label={item.props.label}
              value={value}
              textRight
            />
          );
        }

        const switchvoxOnPremMaintTypeDisabled =
          !customerOrder.switchvoxOnPremEnabled ||
          customerOrder.hasFinalizedLocations ||
          (customerOrder.switchvoxOnPremEnabled && !AuthContext.model.hasSalesOpsPermissions) ||
          disabledState;

        return (
          <SelectControl
            {...item.props}
            key={item.id + 'SelectControl'}
            id={item.id + 'SelectControl'}
            lockedState={lockedState}
            disabledState={switchvoxOnPremMaintTypeDisabled}
            value={customerOrder[item.id]}
            onChange={_value => onQuoteInfoChange(item.id, _value)}
            autoColumns
          />
        );
      case 'switchvoxOnPremMaintTermYears':
        if (!PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
          return null;
        }

        if (customerOrder.switchvoxExistsInOriginalLocation) {
          const value = SWITCHVOX_ON_PREM_TERM_YEARS_OPTIONS.find(
            opt => customerOrder.switchvoxOnPremMaintTermYearsEffective === opt.value
          ).text;

          return (
            <LabelField
              id={item.id + 'LabelField'}
              key={item.id + 'LabelField'}
              parentClassName="short-input"
              label={item.props.label}
              value={value}
              textRight
            />
          );
        }

        const switchvoxOnPremMaintTermYearsDisabled =
          !customerOrder.switchvoxOnPremEnabled || customerOrder.hasFinalizedLocations || disabledState;

        return (
          <SelectControl
            {...item.props}
            key={item.id + 'SelectControl'}
            id={item.id + 'SelectControl'}
            lockedState={lockedState}
            disabledState={switchvoxOnPremMaintTermYearsDisabled}
            value={customerOrder[item.id]}
            onChange={_value => onQuoteInfoChange(item.id, _value)}
            autoColumns
          />
        );
      case 'numSwitchvoxOnPremUsers':
        return !PriceBookContext.model.isSupportsSwitchvoxSIPStation ? null : (
          <NumberControl
            {...item.props}
            parentClassName="short-input"
            key={item.id}
            id={item.id}
            lockedState={lockedState}
            disabledState={disabledState || !customerOrder.switchvoxOnPremEnabled}
            value={customerOrder[item.id]}
            onChange={_value => onQuoteInfoChange(item.id, _value)}
          />
        );
      case 'standaloneServiceNonUCaaS':
        if (!customerOrder.standaloneServiceNonUCaaS) {
          return null;
        }

        return (
          <ToggleControl
            id={item.id}
            key={item.id}
            label={item.props.label}
            parentClassName="short-input"
            lockedState={lockedState}
            value={customerOrder[item.id]}
            disabledState
          />
        );
      case 'smartOfficeLocationsQty':
        if (!PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_SMART_OFFICE)) {
          return null;
        }

        return (
          <NumberControl
            {...item.props}
            parentClassName="smart-office-locations short-input"
            key={item.id + 'LocationsNumber'}
            id={item.id + 'LocationsNumber'}
            // KM-13320: Force disable Number of SmartOffice Locations input for non Sales Ops users
            disabledState={!AuthContext.model.hasSalesOpsPermissions || disabledState}
            lockedState={lockedState}
            value={customerOrder[item.id]}
            max={customerOrder.numLocations}
            onChange={_value => onQuoteInfoChange(item.id, _value)}
          />
        );
      case 'smartOfficeLocation':
        if (!PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_SMART_OFFICE)) {
          return null;
        }

        const toggleValue = Boolean(customerOrder[item.props.id]);
        let disabled = disabledState;

        if (
          (customerOrder.id === null && customerOrder.smartOfficeFromQuoteId !== null) ||
          (customerOrder.id !== null &&
            customerOrder.smartOfficeFromQuoteId !== null &&
            customerOrder.smartOfficeFromQuoteId !== customerOrder.id)
        ) {
          disabled = true;
        }

        return (
          <ToggleControl
            {...item.props}
            key={item.props.id}
            parentClassName="short-input"
            // KM-13320: Force disable SmartOffice Location toggle for non Sales Ops users
            disabledState={!AuthContext.model.hasSalesOpsPermissions || disabled}
            lockedState={lockedState}
            value={toggleValue}
            onChange={_value => onQuoteInfoChange(item.props.id, Number(_value))}
          />
        );
      case 'switchvoxApplianceProvider':
        if (!PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
          return null;
        }

        const switchvoxApplianceProviderDisabled = !customerOrder.switchvoxOnPremEnabled || disabledState;

        return (
          <SelectControl
            {...item.props}
            key={item.id + 'SelectControl'}
            id={item.id + 'SelectControl'}
            lockedState={lockedState}
            disabledState={switchvoxApplianceProviderDisabled}
            value={customerOrder[item.id]}
            onChange={_value => onQuoteInfoChange(item.id, _value)}
            autoColumns
          />
        );
      case 'opportunityProductType':
        if (!AuthContext.model.hasInternalPermissions) {
          return null;
        }

        return (
          <React.Fragment key={'opportunityProductType'}>
            <ToggleControl
              id={item.autoSetTogglePropName}
              key={item.autoSetTogglePropName}
              label="msg_opportunity_product_auto_set_toggle_label"
              disabledState={!AuthContext.model.hasSalesOpsPermissions || disabledState}
              lockedState={lockedState}
              value={customerOrder[item.autoSetTogglePropName]}
              onChange={_value => onQuoteInfoChange(item.autoSetTogglePropName, _value)}
            />
            {customerOrder[item.dropDownPropName] === null && customerOrder[item.autoSetTogglePropName] ? (
              <LabelField
                id={item.dropDownPropName + '-' + customerOrder.uuid}
                label="msg_opportunity_product_type_drop_down_label"
                parentClassName="text-right"
                value={'msg_not_available_abbreviation'}
              />
            ) : (
              <SelectControl
                id={item.dropDownPropName + '-' + customerOrder.uuid}
                key={item.dropDownPropName + '-' + customerOrder.uuid}
                label="msg_opportunity_product_type_drop_down_label"
                disabledState={
                  customerOrder[item.autoSetTogglePropName] || !AuthContext.model.hasSalesOpsPermissions || disabledState
                }
                lockedState={lockedState}
                value={customerOrder[item.dropDownPropName]}
                onChange={_value => onQuoteInfoChange(item.dropDownPropName, _value)}
                options={PriceBookContext.model.availableOpportunityProductTypeSelectControlOptions}
              />
            )}
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <CollapsableCard
      collapsedSections={collapsedSections}
      toggleCollapse={toggleCollapse}
      key={id}
      id={id}
      title={title}
      className="quote-card-container"
    >
      {controller.constantsModel.getQuoteInfoInputs.map(item => getComponent(item, lockedState, disabledState))}
    </CollapsableCard>
  );
};

export default QuoteInformationWrapper;
