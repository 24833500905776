import { createBrowserHistory } from 'history';
import Routes from './Routes';
import config from '../config';
import connectView from '../Controllers/connectView';

export default class AppRouter {
  static Controller = null;
  static appRouterComponent = null;
  static PREVENT_MATCH_CALLBACK = false;
  static PREVENT_CONTROLLER_CALLBACK = false;
  static PREVENT_COMPONENT_UPDATE = false;
  static PREVENT_CONSOLE_LOG = false;

  static history = createBrowserHistory({
    basename: config.basePath,
  });

  /**
   * Alias for AppRouter.history.push
   * @param path
   * @param [state]
   */
  static push(path, state) {
    return AppRouter.history.push(path, state);
  }

  /**
   * Alias for AppRouter.history.replace
   * @param path
   * @param [state]
   */
  static replace(path, state) {
    return AppRouter.history.replace(path, state);
  }

  static navigateWithoutCallback(method, path) {
    AppRouter.PREVENT_CONTROLLER_CALLBACK = true;
    AppRouter.PREVENT_COMPONENT_UPDATE = true;

    return AppRouter.history[method](path);
  }

  /**
   * Pushes new path skipping route listen event
   * @param path
   */
  static pushWithoutCallback(path) {
    return AppRouter.navigateWithoutCallback('push', path);
  }

  /**
   * Replaces path skipping route listen event
   * @param path
   */
  static replaceWithoutCallback(path) {
    return AppRouter.navigateWithoutCallback('replace', path);
  }

  static get location() {
    return AppRouter.history.location;
  }

  static findMatchingRoute(location) {
    const url = location.pathname + location.search + location.hash;

    for (let i = 0; i < Routes.length; i++) {
      const route = Routes[i];
      const matches = url.match(route.match);

      if (matches === null) {
        continue;
      }

      return {
        route,
        matches,
      };
    }

    return {};
  }

  static historyEventListener(location, action) {
    const url = location.pathname + location.search + location.hash;
    const { appRouterComponent } = AppRouter;
    const { route, matches } = AppRouter.findMatchingRoute(location);

    if (!route) {
      if (appRouterComponent) {
        appRouterComponent.setState(() => ({ routeComponent: null }));
      }

      if (config.showConsoleLog) {
        console.warn('AppRouter.historyEventListener matching route not found for ' + url);
      }

      return null;
    }

    const { onMatch, getController, view, viewProps } = route;
    const callbackArguments = { action, location, matches };

    if (!AppRouter.PREVENT_MATCH_CALLBACK && typeof onMatch === 'function') {
      onMatch(callbackArguments);
    }

    if (!AppRouter.PREVENT_CONTROLLER_CALLBACK && typeof getController === 'function') {
      AppRouter.Controller = getController(callbackArguments);
    }

    if (!AppRouter.PREVENT_COMPONENT_UPDATE && appRouterComponent && view) {
      appRouterComponent.setState(() => ({
        routeComponent: AppRouter.Controller ? connectView(view, AppRouter.Controller, viewProps) : view,
      }));
    }

    if (!AppRouter.PREVENT_CONSOLE_LOG && config.showConsoleLog) {
      console.groupCollapsed('AppRouter.historyEventListener', url);
      console.log(AppRouter.Controller);
      console.groupEnd();
    }

    AppRouter.PREVENT_MATCH_CALLBACK = false;
    AppRouter.PREVENT_CONTROLLER_CALLBACK = false;
    AppRouter.PREVENT_COMPONENT_UPDATE = false;
    AppRouter.PREVENT_CONSOLE_LOG = false;

    return true;
  }
}

AppRouter.historyUnlisten = AppRouter.history.listen(AppRouter.historyEventListener);

// Use replace method to force trigger historyEventListener method on page initial load
AppRouter.replace(AppRouter.location);
