import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextControl } from '../../Controls';

class DBA extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    errorText: PropTypes.string,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    id: PropTypes.string,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
  };

  render() {
    const { value, errorText, lockedState, disabledState, id, onChange } = this.props;

    return (
      <div className="dba-component">
        <TextControl
          {...{ lockedState, disabledState, value, errorText }}
          id={id}
          label="msg_dba"
          maxLength={65}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default DBA;
