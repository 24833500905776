import CommonSectionModel from './CommonSectionModel';
import bindMultiple from '../../common/helpers/bindMultiple';
import { ORDER_TYPES, SANGOMA_CX_USER_TYPE_OPTIONS } from '../../common/enums';
import { AuthContext } from '../../Context';

class SangomaCxSettingsSectionModel extends CommonSectionModel {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    //TODO use ConstantsModel props when they will be refactored to static props
    this.id = 'sangoma-cx-settings';
    this.title = 'msg_sangoma_cx_settings';
    this.isGeneralSection = true;

    bindMultiple(this, this.onUserSelectChange, this.onConcurrencyChange, this.onSpeechAnalyticsActiveChange);
  }

  get lockedState() {
    return super.lockedState || this.controller.appState.switchvoxWlwDisabled;
  }

  get showSpeechAnalyticsActiveToggle() {
    return this.isSupportsSpeechAnalytics;
  }

  get speechAnalyticsActiveToggleDisabled() {
    return Boolean(
      this.customerOrder.existingSpeechAnalyticsCustomer ||
        this.customerOrder.sangomaCXUserType !== SANGOMA_CX_USER_TYPE_OPTIONS.PREMIUM ||
        this.disabledState
    );
  }

  get showSangomaCxStandalone() {
    return this.customerOrder.sangomaCXStandalone;
  }

  get sangomaCXConcurrencyDisabled() {
    return Boolean(
      ([ORDER_TYPES.ADD_ON, ORDER_TYPES.REWRITE].includes(this.customerOrder.orderType) &&
        !AuthContext.model.hasSalesOpsPermissions) ||
        this.disabledState
    );
  }

  get userTypeSelectDisabled() {
    return Boolean(
      (this.customerOrder.existingSangomaCXCustomerFromQuoteId !== null && !AuthContext.model.hasSalesOpsPermissions) ||
        this.customerOrder.existingSangomaCXCustomer ||
        this.disabledState
    );
  }

  onUserSelectChange(value) {
    this.customerOrder.onChange('sangomaCXUserType', value);
  }

  onConcurrencyChange(value) {
    this.customerOrder.onChange('sangomaCXConcurrency', value);
  }

  onSpeechAnalyticsActiveChange(value) {
    this.customerOrder.onChange('speechAnalyticsActive', value);
  }
}

export default SangomaCxSettingsSectionModel;
