import React from 'react';
import './notfound.style.css';

export default class NotFoundComponent extends React.Component {
  render() {
    return (
      <section className="not-found-component">
        <h1 className="not-found-header">We're sorry!</h1>
        <h2 className="not-found-sub-header">We can't find the page you are looking for.</h2>
      </section>
    );
  }
}
