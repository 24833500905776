import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ToggleControl } from '../../Controls';

class DBAToggle extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    id: PropTypes.string,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
  };

  render() {
    const { value, lockedState, disabledState, id } = this.props;

    return (
      <div className="dba-toggle-component">
        <ToggleControl
          {...{ lockedState, disabledState, value }}
          id={id}
          label="msg_use_dba_as_customer_name_in_portal"
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

export default DBAToggle;
