import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { AuthContext, PriceBookContext } from '../../../Context';
import SectionsNavigationItem from './SectionsNavigationItem';

function SectionsNavigation({
  sectionsList,
  intl,
  isSpiffVisible,
  isRental,
  isLeaseOptionAvailable,
  onSelectCallback,
  onSelectGeneralSectionItemCallback,
}) {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const listRef = useRef(null);

  const toggleNavigation = event => {
    setIsNavigationOpen(!isNavigationOpen);
  };

  const closeNavigation = () => {
    setIsNavigationOpen(false);
  };

  const openNavigation = () => {
    setIsNavigationOpen(true);
  };

  let navList = [];

  for (let item in sectionsList) {
    if (
      !sectionsList.hasOwnProperty(item) ||
      (item === 'spiff' && !isSpiffVisible) ||
      (item === 'rentalInfo' && (!isRental || !AuthContext.model.hasInternalPermissions)) ||
      (item === 'leaseOption' && !isLeaseOptionAvailable) ||
      (item === 'adminSection' && !AuthContext.model.hasSalesOpsPermissions) ||
      (item === 'daasSettingsSection' && PriceBookContext.model.isSupportsSwitchvoxSIPStation)
    ) {
      continue;
    }

    let navItem = sectionsList[item];

    navList.push(
      <SectionsNavigationItem
        key={`nav-item-${navItem.id}`}
        id={navItem.id}
        isGeneralSection={navItem.isGeneralSection}
        title={navItem.title}
        onSelectGeneralSectionItemCallback={onSelectGeneralSectionItemCallback}
        onSelectCallback={onSelectCallback}
        closeNavigation={closeNavigation}
        listRef={listRef}
      />
    );
  }

  useEffect(() => {
    if (isNavigationOpen) {
      listRef.current.firstChild.focus();
    }
  }, [isNavigationOpen]);

  return (
    <div className="sections-navigation" onFocus={openNavigation}>
      <button
        title={intl.formatMessage({ id: 'msg_navigate_to_section' })}
        className="square toolbar-btn nav-btn no-bg"
        onClick={toggleNavigation}
      >
        <span className="icon-menu" />
      </button>

      {isNavigationOpen && (
        <>
          <div className="navigation-list" ref={listRef}>
            {navList}
          </div>
          <div className="close-navigation-overlay" onClick={closeNavigation}></div>
        </>
      )}
    </div>
  );
}

export default injectIntl(SectionsNavigation);
