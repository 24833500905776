import CustomerInfo from '../../../../CustomerInfo';
import ERROR_TEXT_COLLECTION from '../../../../../common/ERROR_TEXT_COLLECTION';
import { ORDER_TYPES, QUOTE_STATUS } from '../../../../../common/enums';
import apiData from '../../../../../Storage/apiData';
import CollapsableCard from '../../../../CollapsableCard';

const CustomerInformationWrapper = ({
  //CollapsableCard props
  collapsedSections,
  toggleCollapse,
  id,
  title,
  //CustomerInfo props
  customerOrder,
  lockedState,
  disabledState,
  appErrors,
  onCustomerInfoChange,
  //TODO remove use of controller
  controller,
}) => {
  //TODO create separate model and move this flags there
  const customerNameEditable = ORDER_TYPES.NEW_CUSTOMER === customerOrder.orderType;
  const customerNameLocked =
    [QUOTE_STATUS.FINALIZED, QUOTE_STATUS.UNFINALIZED, QUOTE_STATUS.AMENDING].includes(customerOrder.quoteStatus) &&
    !customerOrder.salesOpsInAmending;
  const disableDba =
    (![QUOTE_STATUS.OPEN, QUOTE_STATUS.UNFINALIZED].includes(customerOrder.quoteStatus) ||
      !customerOrder.customerName) &&
    !customerOrder.salesOpsInAmending;
  const { customerNameLength, customerNameIsUnique, dbaError } = appErrors;

  return (
    <CollapsableCard
      collapsedSections={collapsedSections}
      toggleCollapse={toggleCollapse}
      key={id}
      id={id}
      title={title}
      className="quote-card-container"
    >
      <CustomerInfo
        customerData={customerOrder}
        lockedState={lockedState}
        disabledState={disabledState}
        customerNameError={ERROR_TEXT_COLLECTION[customerNameLength || customerNameIsUnique]}
        onChange={onCustomerInfoChange}
        customerNameEditable={customerNameEditable}
        customerNameLocked={customerNameLocked}
        onChangeDba={controller.onChangeDba}
        dbaError={ERROR_TEXT_COLLECTION[dbaError]}
        disableDba={disableDba}
        partnerCanSOBO={apiData.partnerCanSOBO}
        disableSOBO={customerOrder.quoteStatus !== QUOTE_STATUS.OPEN}
        handleCustomerNameCommit={controller.handleCustomerNameCommit}
        handleCustomerNameStateChange={controller.handleCustomerNameStateChange}
        activeAddendumExists={customerOrder.quoteAddendums.some(a => a.active)}
      />
    </CollapsableCard>
  );
};

export default CustomerInformationWrapper;
